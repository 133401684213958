import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about">
      <h2>About Us</h2>
      <p>In the next five years, Doqude.com aspires to be the global leader in empowering organizations and developers to effortlessly modernize, document, and enhance their existing systems.</p>
    </section>
  );
};

export default About;
