import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMailClick = () => {
    window.location.href = 'mailto:doqude@gmail.com';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <h1>Doqude AI</h1>
      <button className="menu-button" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={isMenuOpen ? 'active' : ''}>
        <ul>
          <li><a href="#home" onClick={() => setIsMenuOpen(false)}>Home</a></li>
          <li><a href="#services" onClick={() => setIsMenuOpen(false)}>Services</a></li>
          <li><a href="#about" onClick={() => setIsMenuOpen(false)}>About</a></li>
          <li><a href="#contact" onClick={() => setIsMenuOpen(false)}>Contact</a></li>
        </ul>
      </nav>
      <button className="button" onClick={handleMailClick}>Mail Us</button>
    </header>
  );
};

export default Header;