import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <h1>Modernize Your Legacy Code with Doqude AI</h1>
      <h2>Effortlessly generate detailed technical documentation, complete with architecture diagrams and flowcharts.</h2>
      <a href="#services" className="button">Learn More</a>
    </section>
  );
};

export default Hero;
