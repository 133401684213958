import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <p>Taking Your Development to the Next Level</p>
      <div className="services-list">
        <div className="service">
          <h3>Code Analysis and Optimization</h3>
          <p>Uncover hidden complexities and propose optimized solutions using AI.</p>
        </div>
        <div className="service">
          <h3>Streamlined Documentation Generation</h3>
          <p>Effortlessly generate detailed technical documentation, including architecture diagrams and flowcharts with AI.</p>
        </div>
        <div className="service">
          <h3>AI-Powered Code Analysis</h3>
          <p>Utilize AI to streamline code analysis and uncover hidden complexities for optimized solutions.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
